

/* Container styling */
.welcome-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75vh;
    padding: 0 50px;
    background-color: #f0f4f8;
    overflow: hidden;
  }
  
  /* Welcome content styling */
  .welcome-content {
    flex: 1;
    max-width: 600px;
  }
  
  .welcome-heading {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .welcome-description {
    font-size: 1.5rem;
    color: #586a82;
    margin-bottom: 30px;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .welcome-button {
    padding: 15px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    background-color: #7a70ba;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    animation: fadeIn 2s ease-in-out;
  }
  
  .welcome-button:hover {
    background-color: #7a70ba;
  }
  
  /* Animations */
  .welcome-animations {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .animation-box {
    width: 50px;
    height: 50px;
    background-color: #7a70ba;
    margin: 0 20px;
    border-radius: 10px;
    animation: float 3s ease-in-out infinite;
  }
  
  .animation-box:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  .animation-box:nth-child(3) {
    animation-delay: 1s;
  }
  
  /* Keyframes for Animations */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  