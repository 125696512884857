.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100vh;
  width: 212px;
  
  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  padding-top: 60px;
  transition: transform 0.3s, width 0.3s;
  transform: translateX(calc(-100% - 20px));
  background:$color-template;
  // @include themify($themes) {
  // background: themed('colorBackground');
  // }
  
  a {
  display: block;
  }
  
  &.sidebar--show {
  transform: translateX(0);
  
  .sidebar__back {
  display: block;
  }
  }
  }
  
  .sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none;
  }
  
  .sidebar__link-active {
    background-color: $sidebar-Hover;
  .sidebar__link {
  &:before {
  // color: $color-white;
  opacity: 1;
  }
  }
  p{
  color: $color-template;
  }
  }
  // .sidebar__link-icon {
  // color: black;
  // }
  .sidebar__scroll {
  width: 212.4px;
  
  & > div {
  height: calc(100vh - 60px);
  }
  
  .scrollbar-track {
  
  &.scrollbar-track-y {
  width: 2px;
  margin-right: 3px;
  }
  
  &.scrollbar-track-x {
  display: none !important;
  }
  }
  
  .scrollbar-thumb {
  opacity: 0.3;
  width: 5px;
  }
  }
  
  .sidebar__content {
  padding-top: 15px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  & > div:last-child {
  width: 4px !important;
  
  div {
  transition: height 0.3s;
  opacity: 0.52;
  }
  }
  }
  
  .sidebar__block {
  padding: 15px 0;
  
  @include themify($themes) {
  border-bottom: 1px solid themed('colorBorder');
  }
  
  &:last-child {
  border: none;
  }
  }
  
  .sidebar__link {
  height: 36px;
  width: 200px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  color: $color-template !important;

  
  @include themify($themes) {
  color: themed('colorBackground');
  }
  width:100%;
  &:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background: $color-white;
  opacity: 0;
  transition: all 0.3s;
  }
  
  p {
  position: absolute;
  left: 43px;
  width: 180px;
  transition: left 0.3s;
  top: 51%;
  color:$color-white;
  transform: translateY(-50%);
  }
  &:hover {
  // @include themify($themes) {
  // background-color: themed('sidebar-Hover');
  // }
  background-color: $sidebar-Hover;
  
  &:before {
  opacity: 1;
  }
  }
  svg{
    font-size: large;
  }
  }
  
  .sidebar__link-title {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  display: flex;
  color:$color-white;
  }
  
  .sidebar__link-icon {
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
  color: white;
  height: 100%;
  text-transform: uppercase;
  z-index: 9999;
  
  }

  .sidebar__link-fontawsome-icon {
    margin-right: 10px;
    font-size: 13px !important;
    line-height: 13px;
    color: white;
    margin-bottom: 2px;
  }
  
  .active_sidebar__link-icon {
  color:$color-white !important;
  }
  
  .sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 15px 0;
  background-color:  #578fbd !important;
  & .sidebar__submenu {
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  
  .sidebar__link {
  padding-left: 53px;
  
  p {
  left: 53px;
  }
  }
  }
  
  @include themify($themes) {
  background-color: themed('templateColor');
  }
  
  .sidebar__link {
  padding-left: 22px;
  margin-left: 12px;
  // padding-left: 43px;
  
  
  &:hover {
  background: $color-template;
  
  // @include themify($themes) {
  // background-color: themed('colorBackground');
  // }
  }
  }
  
  .sidebar__link {
  // display: block;
  }
  }
  
  .sidebar__category-icon {
  position: absolute;
  right: 15px;
  font-size: 10px;
  line-height: 14px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: white;
  
  }
  
  .sidebar__link-badge {
  width: fit-content;
  height: 16px;
  min-width: 16px;
  max-width: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white !important;
  color: $color-template;
  font-size: 9px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  text-transform: uppercase;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 28px;  
  }
  
  .sidebar__wrapper--desktop {
  display: none;
  }
  
  .sidebar__category-wrap {
  
  &.sidebar__category-wrap--open {
  
  .sidebar__category-icon {
  transform: rotate(90deg);
  }
  }
  }
  
  .sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  margin-left: 5px;
  background: $color-red;
  }
  
  @media screen and (min-width: 576px) {
  
  .sidebar {
  transform: translateX(0);
  
  &.sidebar--no-desktop {
  transform: translateX(calc(-100% - 20px));
  
  &.sidebar--show {
  transform: translateX(0);
  }
  
  .sidebar__wrapper--mobile {
  display: block;
  }
  }
  
  &.sidebar--collapse {
  width: 55px;
  overflow: visible;
  
  .sidebar__scroll, .sidebar__content {
  width: 55px;
  overflow: visible !important;
  transition: width 0.3s;
  }
  
  .sidebar__submenu {
  padding: 0 0 15px 0;
  transition: 0s;
  }
  
  .sidebar__category-wrap {
  
  &:hover {
  
  .sidebar__category {
  width: 200px;
  
  @include themify($themes) {
  background: themed('templateColor');
  }
  
  &:before {
  opacity: 1;
  }
  }
  
  .sidebar__submenu-wrap {
  width: 200px;
  }
  }
  }
  
  .sidebar__submenu-wrap {
  position: absolute;
  left: 55px;
  width: 0;
  transition: 0.3s;
  overflow: hidden;
  
  .sidebar__link {
  width: 185px;
  padding-left: 15px;
  
  p {
  position: relative;
  left: 0;
  animation: none;
  }
  }
  
  .sidebar__submenu-wrap {
  // position: relative;
  position: relative;
  left: 0;
  
  .sidebar__link {
  padding-left: 30px;
  }
  }
  }
  
  .sidebar__link {
  overflow: hidden;
  width: 55px;
  background-color: transparent;
  
  p {
  position: absolute;
  left: 48px;
  width: 160px;
  }
  
  &:hover {
  width: 200px;
  @include themify($themes) {
  background: themed('templateColor');
  }
  }
  }
  
  .sidebar__category-icon {
  opacity: 0;
  transition: opacity 0s;
  }
  
  .scrollbar-track.scrollbar-track-y {
  margin-right: 188px;
  }
  
  }
  }
  
  .sidebar__wrapper--desktop {
  display: block;
  }
  
  .sidebar__wrapper--mobile {
  display: none;
  }
  
  }
  
  @media screen and (min-width: 992px) {

    .sidebar.sidebar--no-desktop {
      transform: translateX(0);
      display: none;
    }
  }
  .menu-icon{
    color: white;
    vertical-align: middle;
  }