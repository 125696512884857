$animationTime: 0.8s;
$dotSize: 20px;

.loader {
  text-align: center;
  
  span {
    display: inline-block;
    vertical-align: middle;
    width: $dotSize;
    height: $dotSize;
    background: black;
    border-radius: $dotSize;
    animation: loader $animationTime infinite alternate;
    
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    
    &:nth-of-type(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes loader {
  0% {
    opacity: 0.9;
    transform: scale(0.5);
  }
  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}
